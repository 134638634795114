.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body,
main {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  font-family: sans-serif;
  text-align: center;
  color: #fff;
  background: #000;
}

 button {
   position: static;
   left: 50%;
   top: 50%;
   width: 14em;
   height: 2em;
   /* margin-left: 2.5em; */ /*Sends buttons either left or right*/
   margin-top: .5em; /*Lumps one button on top of another*/
   margin-bottom: .5em;
   z-index: 100;
   padding: .25em .5em;
   color: #fff;
   background: #000;
   border: 1px solid #fff;
   border-radius: 4px;
   cursor: pointer;
   font-size: 1.15em;
   font-weight: 200;
   box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
   transition: box-shadow .5s;
 }

 audio{
  color: #fff;
  background: #000;
  border: 4px solid #000;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  margin-top: .5em;
  margin-bottom: .5em; 
  width: 16em;
  height: 2em;
 }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #022E51;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: #A3B4C8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: #000;
}

.Recording-buttons{
  background-color: #CC6D3D
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
